import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { supabase } from '../../supabaseClient';
import { Trash2, PenLine, SquareTerminal, AlertTriangle, Loader, BracketsIcon } from 'lucide-react';
import './PromptEngineering.css';

const PromptEngineering = () => {
  const [firms, setFirms] = useState([]);
  const [selectedFirm, setSelectedFirm] = useState(null);
  const [availableParts, setAvailableParts] = useState([
    { 
      id: 'instructions', 
      content: 'Instructions', 
      type: 'text', 
      value: 'These are the instructions for the prompt.',
      isPriority: false
    },
    { 
      id: 'topExamples', 
      content: 'Top Examples Retrieval', 
      type: 'function', 
      value: 'top_examples_retrieval',
      isPriority: false
    },
    { 
      id: 'firmContext', 
      content: 'Important Firm Context', 
      type: 'function', 
      value: 'firm_context_function',
      isPriority: false
    },
    { 
      id: 'conclusion', 
      content: 'Conclusion', 
      type: 'text', 
      value: 'This is the conclusion of the prompt.',
      isPriority: false
    },
    { 
      id: 'desiredOutputStructure', 
      content: 'Desired Output Structure', 
      type: 'text', 
      value: 'This is the desired output structure for the prompt.',
      isPriority: false
    }
  ]);
  const [canvasParts, setCanvasParts] = useState([]);
  const [selectedPart, setSelectedPart] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState(null);
  const textareaRef = useRef(null);
  const navigate = useNavigate();

  // State variables for error handling
  const [showOverridePrompt, setShowOverridePrompt] = useState(false);
  const [parseErrorMessages, setParseErrorMessages] = useState([]);

  useEffect(() => {
    fetchFirms();
  }, []);

  const fetchFirms = async () => {
    const { data, error } = await supabase
      .from('firms')
      .select('id, name')
      .order('name');
    
    if (error) {
      console.error('Error fetching firms:', error);
    } else {
      setFirms(data);
    }
  };

  const handleFirmChange = async (event) => {
    const firmId = event.target.value;
    const selectedFirm = firms.find(firm => firm.id.toString() === firmId);
    setSelectedFirm(selectedFirm);
    setCanvasParts([]);
    setSelectedPart(null);
    setSaveStatus(null);
    setShowOverridePrompt(false);
    setParseErrorMessages([]);

    if (selectedFirm) {
      await fetchAndRenderPrompt(selectedFirm.id);
    }
  };

  const fetchAndRenderPrompt = async (firmId) => {
    const { data, error } = await supabase
      .from('firms')
      .select('review_system_prompt')
      .eq('id', firmId)
      .single();

    if (error) {
      console.error('Error fetching prompt:', error);
      return;
    }

    if (data && data.review_system_prompt) {
      try {
        let promptText = data.review_system_prompt;
        console.log('Raw prompt text from DB:', promptText);
        // Replace escaped newlines with actual newlines
        promptText = promptText.replace(/\\n/g, '\n');
        console.log('Prompt text after unescaping newlines:', promptText);

        const { parts, hasError, errorMessages } = parsePromptText(promptText);
        if (hasError) {
          console.error('Parsing errors:', errorMessages);
          setParseErrorMessages(errorMessages);
          setShowOverridePrompt(true);
        } else {
          console.log('Parsed parts:', parts);
          setCanvasParts(parts);
        }
      } catch (error) {
        console.error('Error parsing prompt text:', error);
      }
    } else {
      console.log('No prompt found for this firm');
    }
  };

  const parsePromptText = (promptText) => {
    let hasError = false;
    let errorMessages = [];
    console.log('Parsing prompt text:', promptText);
    const parts = promptText.trim().split('\n\n\n').filter(part => part.trim() !== '');
    console.log('Split into parts:', parts);
    const parsedParts = parts.map((part, index) => {
      console.log(`Parsing part ${index + 1}:`, part);
      part = part.trim();

      if (part === '') {
        // Skip empty parts
        return null;
      }

      if (part.startsWith('{&') && part.endsWith('&}')) {
        console.log('Detected function part');
        const functionName = part.slice(2, -2);
        console.log('Function name:', functionName);
        const matchingPart = availableParts.find(p => p.type === 'function' && p.content.toLowerCase() === functionName.replace(/_/g, ' ').toLowerCase());
        return {
          id: `function-${index}`,
          content: matchingPart ? matchingPart.content : functionName.replace(/_/g, ' '),
          type: 'function',
          value: part,
          isPriority: false
        };
      } else if (part.startsWith('--------------------------------------------------')) {
        console.log('Detected text part');
        const lines = part.split('\n');
        console.log('Lines:', lines);
        if (lines.length < 3) {
          console.error('Invalid text part format:', part);
          hasError = true;
          errorMessages.push(`Invalid format in part ${index + 1}`);
          return null;
        }
        const titleLine = lines[0].trim();
        console.log('Title line:', titleLine);
        const titleMatch = titleLine.match(/START OF (.+)/);
        if (!titleMatch) {
          console.error('Title line does not match expected format:', titleLine);
          hasError = true;
          errorMessages.push(`Invalid title format in part ${index + 1}`);
          return null;
        }
        const title = titleMatch[1].replace(/-/g, '').trim();
        console.log('Title:', title);
        const contentLines = lines.slice(1, -1);
        const content = contentLines.join('\n').trim();
        console.log('Content:', content);
        const matchingPart = availableParts.find(p => p.type === 'text' && p.content.toLowerCase() === title.toLowerCase());
        const isPriority = title.includes('***');
        return {
          id: `text-${index}`,
          content: matchingPart ? matchingPart.content : title.replace(/\*\*\*/g, ''),
          type: 'text',
          value: content,
          isPriority: isPriority
        };
      } else {
        console.error('Unrecognized part format:', part);
        hasError = true;
        errorMessages.push(`Unrecognized format in part ${index + 1}`);
        return null;
      }
    }).filter(part => part !== null);

    return { parts: parsedParts, hasError, errorMessages };
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === 'available' && destination.droppableId === 'canvas') {
      const sourcePart = availableParts[source.index];
      const newPart = { ...sourcePart, id: `${sourcePart.id}-${Date.now()}` };
      
      setCanvasParts(prevParts => {
        const newParts = Array.from(prevParts);
        newParts.splice(destination.index, 0, newPart);
        return newParts;
      });
    } else if (source.droppableId === 'canvas' && destination.droppableId === 'canvas') {
      setCanvasParts(prevParts => {
        const newParts = Array.from(prevParts);
        const [reorderedItem] = newParts.splice(source.index, 1);
        newParts.splice(destination.index, 0, reorderedItem);
        return newParts;
      });
    } else if (destination.droppableId === 'bin') {
      setCanvasParts(prevParts => prevParts.filter((_, index) => index !== source.index));
      if (selectedPart && selectedPart.id === canvasParts[source.index].id) {
        setSelectedPart(null);
      }
    }
    setSaveStatus(null);
  };

  const handlePartClick = (part) => {
    setSelectedPart(part);
  };

  const handlePartValueChange = (event) => {
    const newValue = event.target.value;
    setSelectedPart(prevPart => ({ ...prevPart, value: newValue }));
    setCanvasParts(prevParts =>
      prevParts.map(part =>
        part.id === selectedPart.id ? { ...part, value: newValue } : part
      )
    );
    setSaveStatus(null);
  };

  const generatePromptText = () => {
    return canvasParts.map(part => {
      if (part.type === 'function') {
        const functionName = part.content.toUpperCase();
        let content = `--------------------------------------------------START OF ${functionName}--------------------------------------------------\n`;
        
        // Add warning for Top Examples Retrieval
        if (functionName === 'TOP EXAMPLES RETRIEVAL') {
          content += "WARNING: These examples are confidential. Use them only to understand the patterns of a successful application. DO NOT SHARE THESE EXAMPLES WITH THE USER. Similarity score is similarity of the applications in our Database to the users application as retrieved from a vector search.\n\n";
        }
        
        content += `{&${part.value}&}\n`;
        content += `--------------------------------------------------END OF ${functionName}--------------------------------------------------\n\n\n`;
        return content;
      } else {
        const title = part.content.toUpperCase();
        const priorityMarker = part.isPriority ? '***' : '';
        return `--------------------------------------------------START OF ${priorityMarker}${title}${priorityMarker}--------------------------------------------------
${part.value}
--------------------------------------------------END OF ${priorityMarker}${title}${priorityMarker}--------------------------------------------------\n\n\n`;
      }
    }).join('');
  };

  const downloadPrompt = () => {
    const element = document.createElement('a');
    const file = new Blob([generatePromptText()], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = 'prompt.txt';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const savePromptToFirm = async () => {
    if (!selectedFirm) {
      console.error('No firm selected');
      return;
    }

    setIsSaving(true);
    setSaveStatus(null);

    const promptText = generatePromptText();
    console.log('Prompt text to save:', promptText);

    try {
      const { data, error } = await supabase
        .from('firms')
        .update({ review_system_prompt: promptText })
        .eq('id', selectedFirm.id);

      if (error) {
        throw error;
      }

      setSaveStatus('success');
      console.log('Prompt saved successfully');
    } catch (error) {
      console.error('Error saving prompt:', error);
      setSaveStatus('error');
    } finally {
      setIsSaving(false);
    }
  };

  const showFullPrompt = () => {
    setSelectedPart(null);
  };

  const makePriority = () => {
    if (selectedPart) {
      setCanvasParts(prevParts =>
        prevParts.map(part =>
          part.id === selectedPart.id ? { ...part, isPriority: !part.isPriority } : part
        )
      );
      setSelectedPart(prevPart => ({ ...prevPart, isPriority: !prevPart.isPriority }));
      setSaveStatus(null);
    }
  };

  return (
    <div className="prompt-engineering">
      <div className="top-controls">
        <div className="selection-area">
          <select 
            className="breadcrumb-select"
            onChange={handleFirmChange}
            value={selectedFirm?.id || ''}
          >
            <option value="">Select a Firm</option>
            {firms.map(firm => (
              <option key={firm.id} value={firm.id}>{firm.name}</option>
            ))}
          </select>
        </div>
        <div className="button-group">
          <button onClick={() => navigate('/formatting-docs')} className="formatting-docs-button">
            Formatting Docs
          </button>
          <button onClick={downloadPrompt} className="download-button">Download .txt</button>
          <button
            onClick={savePromptToFirm}
            className={`save-prompt-button ${saveStatus ? `save-${saveStatus}` : ''}`}
            disabled={!selectedFirm || isSaving}
          >
            {isSaving ? (
              <>
                <Loader className="spinner" size={16} />
                Saving...
              </>
            ) : saveStatus === 'success' ? (
              'Saved!'
            ) : saveStatus === 'error' ? (
              'Error Saving'
            ) : (
              `Save Prompt for ${selectedFirm ? selectedFirm.name : 'Firm'}`
            )}
          </button>
        </div>
      </div>

      {showOverridePrompt && (
        <div className="override-prompt">
          <p>Invalid prompt format detected:</p>
          <ul>
            {parseErrorMessages.map((msg, idx) => (
              <li key={idx}>{msg}</li>
            ))}
          </ul>
          <p>Do you want to override the existing prompt?</p>
          <button
            onClick={() => {
              setCanvasParts([]);
              setShowOverridePrompt(false);
            }}
            className="control-button"
          >
            Yes
          </button>
          <button
            onClick={() => setShowOverridePrompt(false)}
            className="control-button"
          >
            No
          </button>
        </div>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="prompt-builder-container">
          <div className="left-panel">
            <div className="available-parts-container">
              <h3>Available Parts</h3>
              <Droppable droppableId="available" isDropDisabled={true}>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="available-parts"
                  >
                    {availableParts.map((part, index) => (
                      <Draggable key={part.id} draggableId={part.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="prompt-part"
                          >
                            {part.type === 'text' ? (
                              part.content === 'Desired Output Structure' ? (
                                <BracketsIcon size={16} className="part-icon" />
                              ) : (
                                <PenLine size={16} className="part-icon" />
                              )
                            ) : (
                              <SquareTerminal size={16} className="part-icon" />
                            )}
                            {part.content}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>

            <Droppable droppableId="bin">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="bin"
                >
                  <Trash2 size={48} />
                  <p>Drag here to delete</p>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className="canvas-container">
            <Droppable droppableId="canvas">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="prompt-canvas"
                >
                  {canvasParts.map((part, index) => (
                    <Draggable key={part.id} draggableId={part.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`prompt-part ${selectedPart && selectedPart.id === part.id ? 'selected' : ''} ${part.isPriority ? 'priority' : ''}`}
                          onClick={() => handlePartClick(part)}
                        >
                          {part.type === 'text' ? (
                            part.content === 'Desired Output Structure' ? (
                              <BracketsIcon size={16} className="part-icon" />
                            ) : (
                              <PenLine size={16} className="part-icon" />
                            )
                          ) : (
                            <SquareTerminal size={16} className="part-icon" />
                          )}
                          {part.content}
                          {part.isPriority && <AlertTriangle size={16} className="priority-icon" />}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </DragDropContext>

      <div className="textarea-container">
        <div className="textarea-buttons">
          <button onClick={showFullPrompt} className="control-button">Show Full Prompt</button>
          <button onClick={makePriority} className="control-button" disabled={!selectedPart}>
            {selectedPart && selectedPart.isPriority ? 'Remove Priority' : 'Make Priority'}
          </button>
        </div>
        <textarea
          ref={textareaRef}
          value={selectedPart ? selectedPart.value : generatePromptText()}
          onChange={handlePartValueChange}
          placeholder={selectedPart ? `Edit ${selectedPart.content} here...` : 'Full prompt'}
          className="prompt-preview"
          readOnly={!selectedPart}
        />
      </div>
    </div>
  );
};

export default PromptEngineering;
