import React from 'react';
import './FormattingDocs.css';

const FormattingDocs = () => {
  return (
    <div className="formatting-docs">
      <h1>Formatting Documentation</h1>
      <p>This guide will help you understand the formatting conventions for creating prompts in the prompt engineering tool.</p>

      <h2>Section Formatting</h2>
      <p>Each section is clearly delineated with the following syntax:</p>
      <pre>
        <code>
          ---Section Title---{"\n"}
          [Text content here]
        </code>
      </pre>

      <h2>Priority Sections</h2>
      <p>To convey priority sections, add a prefix <code>***</code> at the beginning of the section title. This indicates to the LLM that this section should be prioritized in importance.</p>
      <pre>
        <code>
          ---***Priority Section Title---{"\n"}
          [Priority text content here]
        </code>
      </pre>

      <h2>Function Activation</h2>
      <p>For sections or portions of text where a function is to be activated, use <code>{'{& &}'}</code> to encapsulate it.</p>
      <pre>
        <code>
          ---Section Title---{"\n"}
          [This is an example of section content.]{"\n"}
          {"{& FunctionName(parameters) &}"}
        </code>
      </pre>
    </div>
  );
};

export default FormattingDocs;
