import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import './UserLeaderboard.css';
import { DateTime } from 'luxon';

const UserLeaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchLeaderboardData();
  }, []);

  const fetchLeaderboardData = async () => {
    setIsLoading(true);
    const now = DateTime.now().setZone('America/Los_Angeles');
    const oneWeekAgo = now.minus({ weeks: 1 });
    const oneMonthAgo = now.minus({ months: 1 });
    const oneYearAgo = now.minus({ years: 1 });

    try {
      const [profiles, oldApplications, newApplications, draftGenerations] = await Promise.all([
        fetchProfiles(),
        fetchOldApplications(oneYearAgo, now),
        fetchNewApplications(oneYearAgo, now),
        fetchDraftGenerations(oneYearAgo, now)
      ]);

      const combinedData = [...oldApplications, ...newApplications, ...draftGenerations];

      const userScores = calculateUserScores(profiles, combinedData, oneWeekAgo, oneMonthAgo);

      userScores.sort((a, b) => b.score - a.score);
      setLeaderboard(userScores.map((user, index) => ({ ...user, rank: index + 1 })));
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProfiles = async () => {
    const { data, error } = await supabase.from('profiles').select('id, name, email');
    if (error) throw error;
    return data;
  };

  const fetchOldApplications = async (startDate, endDate) => {
    const { data, error } = await supabase
      .from('applications')
      .select('*')
      .gte('timestamp', startDate.toISO())
      .lte('timestamp', endDate.toISO());
    if (error) throw error;
    return data.map(app => ({ ...app, type: 'application', isOld: true }));
  };

  const fetchNewApplications = async (startDate, endDate) => {
    const { data, error } = await supabase
      .from('applications_vector_history')
      .select('*')
      .gte('timestamp', startDate.toISO())
      .lte('timestamp', endDate.toISO());
    if (error) throw error;
    return data.map(app => ({ ...app, type: 'application', isOld: false }));
  };

  const fetchDraftGenerations = async (startDate, endDate) => {
    const { data, error } = await supabase
      .from('draft_generations')
      .select('*')
      .gte('created_at', startDate.toISO())
      .lte('created_at', endDate.toISO());
    if (error) throw error;
    return data.map(draft => ({ ...draft, type: 'draft', timestamp: draft.created_at }));
  };

  const calculateUserScores = (profiles, combinedData, oneWeekAgo, oneMonthAgo) => {
    return profiles.map(profile => {
      const userEntries = combinedData.filter(entry => 
        (entry.email === profile.email) || (entry.userId === profile.id)
      );

      const score = userEntries.reduce((acc, entry) => {
        const entryDate = DateTime.fromISO(entry.timestamp);
        if (entryDate >= oneWeekAgo) return acc + 10;
        if (entryDate >= oneMonthAgo) return acc + 5;
        return acc + 2;
      }, 0);

      const lastActiveDate = userEntries.length > 0
        ? DateTime.max(...userEntries.map(e => DateTime.fromISO(e.timestamp)))
        : null;

      return {
        ...profile,
        score,
        totalEntries: userEntries.length,
        draftEntries: userEntries.filter(e => e.type === 'draft').length,
        applicationEntries: userEntries.filter(e => e.type === 'application').length,
        lastActiveDate
      };
    });
  };

  const openUserProfile = (userId) => {
    window.open(`/users/${userId}`, '_blank');
  };

  if (isLoading) {
    return <div className="loading">Loading leaderboard...</div>;
  }

  return (
    <div className="user-leaderboard">
      <h2>User Leaderboard</h2>
      <div className="leaderboard-grid">
        {leaderboard.map((user) => (
          <div 
            key={user.id} 
            className="user-card"
            onClick={() => openUserProfile(user.id)}
          >
            <div className="user-rank">#{user.rank}</div>
            <div className="user-info">
              <h3>{user.name}</h3>
              <p className="user-email">{user.email}</p>
              <p className="user-uuid">UUID: {user.id}</p>
              <p className="user-score">Score: {user.score}</p>
              <p className="user-entries">Total Entries: {user.totalEntries}</p>
              <p className="user-subentries">
                Applications: {user.applicationEntries} | Drafts: {user.draftEntries}
              </p>
              <p className="user-last-active">
                Last Active: {user.lastActiveDate ? user.lastActiveDate.toLocaleString(DateTime.DATE_MED) : 'N/A'}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserLeaderboard;