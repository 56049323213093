import React, { useState } from 'react';
import './PasswordPrompt.css';

const PasswordPrompt = ({ onCorrectPassword }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'T@lentlex12345678') {
      onCorrectPassword();
    } else {
      setError('Incorrect password. Please try again.');
    }
  };

  return (
    <div className="password-prompt-overlay">
      <div className="password-prompt-container">
        <h2 className="password-prompt-title">Enter Password</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            className="password-prompt-input"
          />
          {error && <p className="password-prompt-error">{error}</p>}
          <button type="submit" className="password-prompt-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordPrompt;