import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import './SubmissionsManager.css';
import { Trash2 } from 'lucide-react';

const SubmissionsManager = () => {
  const [submissions, setSubmissions] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firms, setFirms] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentSubmission, setCurrentSubmission] = useState({
    id: null,
    firm_id: '',
    user_id: '',
    submit_time: '',
    submission_status: ''
  });
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [userSearch, setUserSearch] = useState('');
  const [filters, setFilters] = useState({
    status: '',
    firm: '',
    user: ''
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchSubmissions();
    fetchFirms();
    fetchUsers();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [submissions, filters]);

  const fetchSubmissions = async () => {
    try {
      const { data, error } = await supabase
        .from('firm_user_table')
        .select('*')
        .not('submit_time', 'is', null)
        .order('submit_time', { ascending: false });

      if (error) throw error;

      setSubmissions(data);
      setFilteredSubmissions(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching submissions:', error);
      setLoading(false);
    }
  };

  const fetchFirms = async () => {
    try {
      const { data, error } = await supabase
        .from('firms')
        .select('id, name');

      if (error) throw error;

      setFirms(data);
    } catch (error) {
      console.error('Error fetching firms:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('id, name, email');

      if (error) throw error;

      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentSubmission(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { id, firm_id, user_id, submit_time, submission_status } = currentSubmission;
      
      if (id) {
        // Update existing submission
        const { data, error } = await supabase
          .from('firm_user_table')
          .update({ firm_id, user_id, submit_time, submission_status })
          .eq('id', id);

        if (error) throw error;
      } else {
        // Insert new submission
        const { data, error } = await supabase
          .from('firm_user_table')
          .insert([{ firm_id, user_id, submit_time, submission_status }]);

        if (error) throw error;
      }

      fetchSubmissions();
      resetForm();
    } catch (error) {
      console.error('Error saving submission:', error);
    }
  };

  const handleEdit = (submission) => {
    setCurrentSubmission({
      id: submission.id,
      firm_id: submission.firm_id,
      user_id: submission.user_id,
      submit_time: submission.submit_time.slice(0, 16), // Format date for input
      submission_status: submission.submission_status
    });
    setIsEditing(true);
  };

  const handleDelete = async (e, id) => {
    e.stopPropagation(); // Prevent row click event
    if (window.confirm('Are you sure you want to delete this submission?')) {
      try {
        const { error } = await supabase
          .from('firm_user_table')
          .delete()
          .eq('id', id);
        
        if (error) throw error;
        
        fetchSubmissions();
      } catch (error) {
        console.error('Error deleting submission:', error);
      }
    }
  };

  const resetForm = () => {
    setCurrentSubmission({
      id: null,
      firm_id: '',
      user_id: '',
      submit_time: '',
      submission_status: ''
    });
    setIsEditing(false);
  };

  const handleViewRawRequest = (e, rawRequest) => {
    e.stopPropagation(); // Prevent row click event
    setPopupContent(rawRequest);
    setShowPopup(true);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const applyFilters = () => {
    let filtered = submissions;

    if (filters.status) {
      filtered = filtered.filter(sub => sub.submission_status === filters.status);
    }
    if (filters.firm) {
      filtered = filtered.filter(sub => sub.firm_id === filters.firm);
    }
    if (filters.user) {
      filtered = filtered.filter(sub => sub.user_id === filters.user);
    }

    setFilteredSubmissions(filtered);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'submitted':
        return 'status-submitted';
      case 'unsubmitted':
        return 'status-unsubmitted';
      case 'accepted':
        return 'status-accepted';
      case 'rejected':
        return 'status-rejected';
      case 'pending':
        return 'status-pending';
      default:
        return '';
    }
  };

  const getUserRowColor = (userId) => {
    const userIndex = users.findIndex(user => user.id === userId);
    const shade = Math.floor((userIndex / users.length) * 40);
    return `rgb(${215 - shade}, ${215 - shade}, ${215 - shade})`;
  };

  const formatToSFTime = (dateString) => {
    const options = {
      timeZone: 'America/Los_Angeles',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    };
    return new Date(dateString).toLocaleString('en-US', options);
  };

  if (loading) {
    return <div className="loading">Loading submissions...</div>;
  }

  return (
    <div className="submissions-manager">
      <div className="submissions-list">
        <h1>Submissions Manager</h1>
        <div className="filters">
          <select name="status" value={filters.status} onChange={handleFilterChange}>
            <option value="">All Statuses</option>
            <option value="submitted">Submitted</option>
            <option value="unsubmitted">Unsubmitted</option>
            <option value="accepted">Accepted</option>
            <option value="rejected">Rejected</option>
            <option value="pending">Pending</option>
          </select>
          <select name="firm" value={filters.firm} onChange={handleFilterChange}>
            <option value="">All Firms</option>
            {firms.map(firm => (
              <option key={firm.id} value={firm.id}>{firm.name}</option>
            ))}
          </select>
          <select name="user" value={filters.user} onChange={handleFilterChange}>
            <option value="">All Users</option>
            {users.filter(user => submissions.some(sub => sub.user_id === user.id)).map(user => (
              <option key={user.id} value={user.id}>{user.name || 'Unnamed'}</option>
            ))}
          </select>
        </div>
        <table className="submissions-table">
          <thead>
            <tr>
              <th>User Name</th>
              <th>Firm Name</th>
              <th>Submit Time (SF)</th>
              <th>Status</th>
              <th>Actions</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredSubmissions.map((submission) => (
              <tr 
                key={submission.id} 
                style={{ backgroundColor: getUserRowColor(submission.user_id) }}
                onClick={() => handleEdit(submission)}
                className="clickable-row"
              >
                <td>{users.find(user => user.id === submission.user_id)?.name || 'Unknown User'}</td>
                <td>{firms.find(firm => firm.id === submission.firm_id)?.name || 'Unknown Firm'}</td>
                <td>{formatToSFTime(submission.submit_time)}</td>
                <td>
                  <span className={`status-skew ${getStatusColor(submission.submission_status)}`}>
                    {submission.submission_status}
                  </span>
                </td>
                <td>
                  {submission.raw_request && (
                    <button 
                      className="raw-request-button"
                      onClick={(e) => handleViewRawRequest(e, submission.raw_request)}
                    >
                      View Raw Request
                    </button>
                  )}
                </td>
                <td className="delete-cell">
                  <button 
                    className="delete-button"
                    onClick={(e) => handleDelete(e, submission.id)}
                  >
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="submission-form-container">
        <h2>{isEditing ? 'Edit Submission' : 'Create New Submission'}</h2>
        <form onSubmit={handleSubmit} className="submission-form">
          <select
            name="firm_id"
            value={currentSubmission.firm_id}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Firm</option>
            {firms.map(firm => (
              <option key={firm.id} value={firm.id}>{firm.name}</option>
            ))}
          </select>

          <div className="user-search">
            <input
              type="text"
              placeholder="Search user by name or email"
              value={userSearch}
              onChange={(e) => setUserSearch(e.target.value)}
            />
            <select
              name="user_id"
              value={currentSubmission.user_id}
              onChange={handleInputChange}
              required
            >
              <option value="">Select User</option>
              {users
                .filter(user => 
                  (user.name?.toLowerCase().includes(userSearch.toLowerCase()) || false) ||
                  (user.email?.toLowerCase().includes(userSearch.toLowerCase()) || false)
                )
                .map(user => (
                  <option key={user.id} value={user.id}>{user.name || 'Unnamed'} ({user.email || 'No email'})</option>
                ))
              }
            </select>
          </div>

          <input
            type="datetime-local"
            name="submit_time"
            value={currentSubmission.submit_time}
            onChange={handleInputChange}
            required
          />

          <select
            name="submission_status"
            value={currentSubmission.submission_status}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Status</option>
            <option value="submitted">Submitted</option>
            <option value="unsubmitted">Unsubmitted</option>
            <option value="accepted">Accepted</option>
            <option value="rejected">Rejected</option>
            <option value="pending">Pending</option>
          </select>

          <button type="submit">{isEditing ? 'Update Submission' : 'Create Submission'}</button>
          {isEditing && (
            <button type="button" onClick={resetForm} className="cancel-button">
              Cancel Edit
            </button>
          )}
        </form>
      </div>

      {showPopup && (
        <div className="popup-overlay" onClick={() => setShowPopup(false)}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <h2>Raw Request</h2>
            <pre>{popupContent}</pre>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubmissionsManager;