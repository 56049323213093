import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { supabase } from '../../supabaseClient';
import './UserSearch.css';
import IndividualProfile from '../IndividualProfile';
import { debounce } from 'lodash';

const UserSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const searchInputRef = useRef(null);

  useEffect(() => {
    console.log('UserSearch component mounted');
    fetchAllUsers();
    return () => {
      console.log('UserSearch component unmounted');
    };
  }, []);

  const fetchAllUsers = async () => {
    console.log('Fetching all users');
    const startTime = performance.now();
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('id, name, email');

      if (error) throw error;
      setAllUsers(data);
      console.log(`Fetched ${data.length} users`);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
    const endTime = performance.now();
    console.log(`Fetching users took ${endTime - startTime} ms`);
  };

  const debouncedSearch = useMemo(
    () => debounce((term) => {
      console.log(`Searching for: "${term}"`);
      const startTime = performance.now();
      if (term.length > 2) {
        const filteredUsers = allUsers.filter(user => 
          user.name?.toLowerCase().includes(term.toLowerCase()) ||
          user.email?.toLowerCase().includes(term.toLowerCase())
        ).slice(0, 5);
        setSuggestions(filteredUsers);
        setShowDropdown(true);
        console.log(`Found ${filteredUsers.length} suggestions`);
      } else {
        setSuggestions([]);
        setShowDropdown(false);
      }
      const endTime = performance.now();
      console.log(`Search took ${endTime - startTime} ms`);
    }, 300),
    [allUsers]
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
    return () => debouncedSearch.cancel();
  }, [searchTerm, debouncedSearch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelectUser = useCallback((userId) => {
    console.log(`User selected: ${userId}`);
    const startTime = performance.now();
    setSelectedUserId(userId);
    setShowDropdown(false);
    setSearchTerm('');
    const endTime = performance.now();
    console.log(`Setting selected user took ${endTime - startTime} ms`);
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleSearchFocus = useCallback(() => {
    setShowDropdown(true);
  }, []);

  const renderSuggestions = useMemo(() => {
    if (!showDropdown || suggestions.length === 0) return null;

    return (
      <ul className="suggestions-list">
        {suggestions.map((user) => (
          <li key={user.id} onClick={() => handleSelectUser(user.id)}>
            {user.name} | {user.email}
          </li>
        ))}
      </ul>
    );
  }, [showDropdown, suggestions, handleSelectUser]);

  console.log('Rendering UserSearch component');
  return (
    <div className="user-search-admin">
      <h2>User Search</h2>
      <div className="search-container" ref={searchInputRef}>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={handleSearchFocus}
          placeholder="Search by name or email"
          className="search-input"
        />
        {renderSuggestions}
      </div>
      {selectedUserId && <IndividualProfile userId={selectedUserId} />}
    </div>
  );
};

export default UserSearch;