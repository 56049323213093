import React, { useState, useEffect } from 'react';
import { Menu } from 'lucide-react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import QueryPage from './Search/QueryPage';
import UserSearch from './Search/UserSearch';
import UserLeaderboard from './Search/UserLeaderboard';
import IndividualProfile from './IndividualProfile';
import ContributionHistory from './components/ContributionHistory';
import FirmPopularityTracker from './components/FirmPopularityTracker';
import SignUpLog from './components/SignUpLog';
import PromptEngineering from './Search/PromptEngineering';
import FormattingDocs from './Search/FormattingDocs';
import PromptList from './Search/PromptList'
import SubmissionsManager from './Search/SubmissionsManager';
import ExampleManager from './Search/ExampleManager';

import './Admin.css';
import { DateTime } from 'luxon';

const Admin = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [signupData, setSignupData] = useState({});
  const [todaySignups, setTodaySignups] = useState(0);
  const [activityData, setActivityData] = useState({});
  const [todayApplications, setTodayApplications] = useState(0);
  const [todayDraftGenerations, setTodayDraftGenerations] = useState(0);
  const [firmPopularityData, setFirmPopularityData] = useState([]);
  const [timeRange, setTimeRange] = useState('total');
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      fetchSignupData();
      fetchActivityData();
      fetchFirmPopularityData();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === '/') {
      fetchActivityData();
      fetchFirmPopularityData();
    }
  }, [timeRange, location.pathname]);

  const fetchSignupData = async () => {
    const now = DateTime.now().setZone('America/Los_Angeles');
    const endDate = now.endOf('day');
    const startDate = endDate.minus({ days: 365 });

    const { data, error } = await supabase
      .from('profiles')
      .select('created_at')
      .gte('created_at', startDate.toISO())
      .lte('created_at', endDate.toISO());

    if (error) {
      console.error('Error fetching signup data:', error);
      return;
    }

    const signupsByDate = {};
    let todayCount = 0;
    const todayPDT = now.toISODate();

    // Initialize signupsByDate with all dates in the range
    for (let d = startDate; d <= endDate; d = d.plus({ days: 1 })) {
      signupsByDate[d.toISODate()] = 0;
    }

    // Count signups for each day
    data.forEach((item) => {
      const pdtDate = DateTime.fromISO(item.created_at)
        .setZone('America/Los_Angeles')
        .toISODate();

      if (signupsByDate[pdtDate] !== undefined) {
        signupsByDate[pdtDate]++;
      }

      if (pdtDate === todayPDT) {
        todayCount++;
      }
    });

    setSignupData(signupsByDate);
    setTodaySignups(todayCount);
  };

  const fetchActivityData = async () => {
    const now = DateTime.now().setZone('America/Los_Angeles');
    const endDate = now.endOf('day');
    let startDate = getStartDateForTimeRange(now, timeRange);

    try {
      const [applicationsData, vectorHistoryData, draftGenerationsData] = await Promise.all([
        fetchApplicationsData(startDate, endDate),
        fetchVectorHistoryData(startDate, endDate),
        fetchDraftGenerationsData(startDate, endDate)
      ]);

      const combinedData = [
        ...applicationsData.map(item => ({ ...item, type: 'application' })),
        ...vectorHistoryData.map(item => ({ ...item, type: 'vector' })),
        ...draftGenerationsData.map(item => ({ ...item, type: 'draft', timestamp: item.created_at }))
      ];

      const activityByDate = processActivityByDate(combinedData);
      setActivityData(activityByDate);

      const todayPDT = now.toISODate();
      setTodayApplications(activityByDate[todayPDT]?.applications || 0);
      setTodayDraftGenerations(activityByDate[todayPDT]?.draftGenerations || 0);
    } catch (error) {
      console.error('Error fetching activity data:', error);
    }
  };

  const fetchApplicationsData = async (startDate, endDate) => {
    const { data, error } = await supabase
      .from('applications')
      .select('timestamp, id')
      .gte('timestamp', startDate.toISO())
      .lte('timestamp', endDate.toISO());

    if (error) throw error;
    return data;
  };

  const fetchVectorHistoryData = async (startDate, endDate) => {
    const { data, error } = await supabase
      .from('applications_vector_history')
      .select('timestamp, id')
      .gte('timestamp', startDate.toISO())
      .lte('timestamp', endDate.toISO());

    if (error) throw error;
    return data;
  };

  const fetchDraftGenerationsData = async (startDate, endDate) => {
    const { data, error } = await supabase
      .from('draft_generations')
      .select('created_at, id')
      .gte('created_at', startDate.toISO())
      .lte('created_at', endDate.toISO());

    if (error) throw error;
    return data;
  };

  const fetchFirmPopularityData = async () => {
    const now = DateTime.now().setZone('America/Los_Angeles');
    const endDate = now.endOf('day');
    let startDate = getStartDateForTimeRange(now, timeRange);

    try {
      const [applicationsData, draftGenerationsData] = await Promise.all([
        fetchFirmDataFromApplications(startDate, endDate),
        fetchFirmDataFromDraftGenerations(startDate, endDate)
      ]);

      const combinedData = [...applicationsData, ...draftGenerationsData];
      const firmPopularityData = processFirmPopularity(combinedData);
      setFirmPopularityData(firmPopularityData);
    } catch (error) {
      console.error('Error fetching firm popularity data:', error);
    }
  };

  const fetchFirmDataFromApplications = async (startDate, endDate) => {
    const { data, error } = await supabase
      .from('applications')
      .select('firm')
      .gte('timestamp', startDate.toISO())
      .lte('timestamp', endDate.toISO());

    if (error) throw error;
    return data;
  };

  const fetchFirmDataFromDraftGenerations = async (startDate, endDate) => {
    const { data, error } = await supabase
      .from('draft_generations')
      .select('firm')
      .gte('created_at', startDate.toISO())
      .lte('created_at', endDate.toISO());

    if (error) throw error;
    return data;
  };

  const getStartDateForTimeRange = (now, range) => {
    switch (range) {
      case 'today':
        return now.startOf('day');
      case 'week':
        return now.minus({ weeks: 1 }).startOf('day');
      case 'month':
        return now.minus({ months: 1 }).startOf('day');
      case 'total':
      default:
        return now.minus({ years: 1 }).startOf('day');
    }
  };

  const processActivityByDate = (combinedArray) => {
    const activityByDate = {};
    combinedArray.forEach(item => {
      const pdtDate = DateTime.fromISO(item.timestamp)
        .setZone('America/Los_Angeles')
        .toISODate();
      if (!activityByDate[pdtDate]) {
        activityByDate[pdtDate] = { applications: 0, draftGenerations: 0 };
      }
      if (item.type === 'application' || item.type === 'vector') {
        activityByDate[pdtDate].applications++;
      } else if (item.type === 'draft') {
        activityByDate[pdtDate].draftGenerations++;
      }
    });
    return activityByDate;
  };

  const processFirmPopularity = (combinedArray) => {
    const firmCounts = {};
    combinedArray.forEach(item => {
      if (item.firm) {
        firmCounts[item.firm] = (firmCounts[item.firm] || 0) + 1;
      }
    });
    return Object.entries(firmCounts)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 10)
      .map(([firm, count]) => ({ firm, count }));
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setShowOverlay(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    setShowOverlay(false);
  };

  return (
    <div className="layout">
      <div className={`sidebar ${menuOpen ? 'open' : ''}`}>
        <div className="sidebar-content">
          <div className="logo">TalentLex Admin</div>
          <nav className="nav">
            <ul className="nav-list">
              <li className="section-title">Admin Tools</li>
              <div className="separator"></div>
              <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                <Link to="/">Home</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/query' ? 'active' : ''}`}>
                <Link to="/query">Query Page</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/users' ? 'active' : ''}`}>
                <Link to="/users">User Search</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/leaderboard' ? 'active' : ''}`}>
                <Link to="/leaderboard">User Leaderboard</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/prompt-engineering' ? 'active' : ''}`}>
                <Link to="/prompt-engineering">Prompt Engineering</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/prompt-tree' ? 'active' : ''}`}>
                <Link to="/prompt-tree">Prompt List</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/submissions-manager' ? 'active' : ''}`}>
                <Link to="/submissions-manager">Submissions Manager</Link>
              </li>
              <li className={`nav-item ${location.pathname === '/example-manager' ? 'active' : ''}`}>
                <Link to="/example-manager">Example Manager</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="user-info">
          <div className="user-name">Admin User</div>
          <button className="logout-btn">Log Out</button>
        </div>
      </div>
      <div className="main-content">
        <button className="menu-toggle" onClick={toggleMenu}>
          <Menu size={24} />
        </button>
        <div className="content-area">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <h1 className="page-title">Admin Dashboard</h1>
                  <p className="welcome-text">
                    Welcome to the TalentLex Admin Dashboard. Use the Query Page to access and analyze
                    data, the User Search to find and view user profiles, or check the User Leaderboard
                    to see top contributors.
                  </p>
                  <div className="dashboard-content">
                    <div className="dashboard-row">
                      <div className="half-column">
                        <ContributionHistory
                          data={signupData}
                          title="Sign Ups"
                          todayCount={`${todaySignups} signups today so far`}
                        />
                      </div>
                      <div className="half-column">
                        <ContributionHistory
                          data={activityData}
                          title="Applications and Draft Generations"
                          todayCount={`${todayApplications + todayDraftGenerations} total items today (${todayApplications} applications, ${todayDraftGenerations} draft generations)`}
                          isActivity={true}
                        />
                      </div>
                    </div>
                    <div className="dashboard-row">
                      <div className="half-column">
                        <FirmPopularityTracker
                          firmPopularityData={firmPopularityData}
                          timeRange={timeRange}
                          setTimeRange={setTimeRange}
                        />
                      </div>
                      <div className="half-column">
                        <SignUpLog />
                      </div>
                    </div>
                  </div>
                </>
              }
            />
            <Route path="/query" element={<QueryPage />} />
            <Route path="/users" element={<UserSearch />} />
            <Route path="/users/:userId" element={<IndividualProfile />} />
            <Route path="/leaderboard" element={<UserLeaderboard />} />
            <Route path="/prompt-engineering" element={<PromptEngineering />} />
            <Route path="/formatting-docs" element={<FormattingDocs />} />
            <Route path="/prompt-tree" element={<PromptList />} />
            <Route path="/submissions-manager" element={<SubmissionsManager />} />
            <Route path="/example-manager" element={<ExampleManager />} />
          </Routes>
        </div>
      </div>
      {showOverlay && <div className="menu-overlay" onClick={closeMenu}></div>}
    </div>
  );
};

export default Admin;