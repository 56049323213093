import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import OpenAI from 'openai';
import './ExampleManager.css';
import { Trash2 } from 'lucide-react';

const OPENAI_KEY = "sk-proj-5MN52DibgpFgQfMKAJiVT3BlbkFJktsJW2uQhIuIGvE24viw";
const openai = new OpenAI({
  apiKey: OPENAI_KEY,
  dangerouslyAllowBrowser: true
});

const ExampleManager = () => {
  const [users, setUsers] = useState([]);
  const [firms, setFirms] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedFirm, setSelectedFirm] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [applicationText, setApplicationText] = useState('');
  const [status, setStatus] = useState('');
  const [examples, setExamples] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
    fetchFirms();
    fetchExamples();
  }, []);

  const fetchUsers = async () => {
    const { data, error } = await supabase
      .from('profiles')
      .select('id, name, email')
      .order('name');
    if (error) console.error('Error fetching users:', error);
    else setUsers(data || []);
  };

  const fetchFirms = async () => {
    const { data, error } = await supabase
      .from('firms')
      .select('id, name')
      .order('name');
    if (error) console.error('Error fetching firms:', error);
    else setFirms(data || []);
  };

  const fetchQuestions = async (firmName) => {
    const { data, error } = await supabase
      .from('questions')
      .select('id, question')
      .eq('firm', firmName)
      .order('question');
    if (error) console.error('Error fetching questions:', error);
    else setQuestions(data || []);
  };

  const fetchExamples = async () => {
    const { data, error } = await supabase
      .from('examples_vector')
      .select(`
        id,
        user_id,
        firm_id,
        question,
        application_text,
        vector,
        status
      `)
      .order('id', { ascending: false });
    
    if (error) {
      console.error('Error fetching examples:', error);
    } else {
      const userIds = [...new Set(data.map(example => example.user_id))];
      const firmIds = [...new Set(data.map(example => example.firm_id))];
      
      const [usersData, firmsData] = await Promise.all([
        fetchUsersByIds(userIds),
        fetchFirmsByIds(firmIds)
      ]);

      const examplesWithRelations = data.map(example => ({
        ...example,
        user: usersData.find(user => user.id === example.user_id),
        firm: firmsData.find(firm => firm.id === example.firm_id)
      }));

      setExamples(examplesWithRelations);
    }
  };

  const fetchUsersByIds = async (userIds) => {
    const { data, error } = await supabase
      .from('profiles')
      .select('id, name, email')
      .in('id', userIds);
    
    if (error) console.error('Error fetching users:', error);
    return data || [];
  };

  const fetchFirmsByIds = async (firmIds) => {
    const { data, error } = await supabase
      .from('firms')
      .select('id, name')
      .in('id', firmIds);
    
    if (error) console.error('Error fetching firms:', error);
    return data || [];
  };

  const getEmbedding = async (text) => {
    try {
      const response = await openai.embeddings.create({
        model: "text-embedding-3-small",
        input: text,
      });
      return response.data[0].embedding;
    } catch (error) {
      console.error('Error getting embedding:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const vector = await getEmbedding(applicationText);
      const { data, error } = await supabase
        .from('examples_vector')
        .insert([
          {
            user_id: selectedUser,
            firm_id: selectedFirm,
            question: selectedQuestion,
            application_text: applicationText,
            vector: vector,
            status: status,
          }
        ]);
      if (error) throw error;
      alert('Example submitted and vectorized successfully!');
      setSelectedUser('');
      setSelectedFirm('');
      setSelectedQuestion('');
      setApplicationText('');
      setStatus('');
      fetchExamples();
    } catch (error) {
      console.error('Error submitting example:', error);
      alert('Error submitting example. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this example?')) {
      try {
        const { error } = await supabase
          .from('examples_vector')
          .delete()
          .eq('id', id);
        
        if (error) throw error;
        alert('Example deleted successfully!');
        fetchExamples();
      } catch (error) {
        console.error('Error deleting example:', error);
        alert('Error deleting example. Please try again.');
      }
    }
  };

  const filteredUsers = users.filter(user =>
    user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getStatusColor = (status) => {
    switch (status) {
      case 'accepted':
        return 'status-accepted';
      case 'rejected':
        return 'status-rejected';
      case 'pending':
        return 'status-pending';
      default:
        return '';
    }
  };

  return (
    <div className="example-manager">
      <div className="examples-list">
        <h1>Example Manager</h1>
        <table className="examples-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>User</th>
              <th>Firm</th>
              <th>Question</th>
              <th>Application Text</th>
              <th>Vector</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {examples.map(example => (
              <tr key={example.id}>
                <td>{example.id}</td>
                <td>{example.user?.name} ({example.user?.email})</td>
                <td>{example.firm?.name}</td>
                <td>{example.question}</td>
                <td>{example.application_text.substring(0, 100)}...</td>
                <td>{example.vector ? 'Yes' : 'No'}</td>
                <td>
                  <span className={`status-skew ${getStatusColor(example.status)}`}>
                    {example.status || 'N/A'}
                  </span>
                </td>
                <td>
                  <button onClick={() => handleDelete(example.id)} className="delete-button">
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="example-form-container">
        <h2>Create New Example</h2>
        <form onSubmit={handleSubmit} className="example-form">
          <div className="user-search">
            <input
              type="text"
              placeholder="Search user by name or email"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              id="user-select"
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              required
            >
              <option value="">Select a user</option>
              {filteredUsers.map(user => (
                <option key={user.id} value={user.id}>
                  {user.name} ({user.email})
                </option>
              ))}
            </select>
          </div>
          <select
            id="firm-select"
            value={selectedFirm}
            onChange={(e) => {
              const firmId = e.target.value;
              setSelectedFirm(firmId);
              const selectedFirmName = firms.find(firm => firm.id === firmId)?.name;
              if (selectedFirmName) {
                fetchQuestions(selectedFirmName);
              }
            }}
            required
          >
            <option value="">Select a firm</option>
            {firms.map(firm => (
              <option key={firm.id} value={firm.id}>{firm.name}</option>
            ))}
          </select>
          {selectedFirm && (
            <select
              id="question-select"
              value={selectedQuestion}
              onChange={(e) => setSelectedQuestion(e.target.value)}
              required
            >
              <option value="">Select a question</option>
              {questions.map(q => (
                <option key={q.id} value={q.question}>{q.question}</option>
              ))}
            </select>
          )}
          <textarea
            id="application-text"
            value={applicationText}
            onChange={(e) => setApplicationText(e.target.value)}
            rows="10"
            placeholder="Enter application text"
            required
          ></textarea>
          <select
            id="status-select"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            required
          >
            <option value="">Select status</option>
            <option value="accepted">Accepted</option>
            <option value="rejected">Rejected</option>
            <option value="pending">Pending</option>
          </select>
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Submitting...' : 'Submit and Vectorize'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ExampleManager;