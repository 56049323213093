import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ContributionHistory from './components/ContributionHistory';
import './IndividualProfile.css';
import { DateTime } from 'luxon';

const IndividualProfile = ({ userId: propUserId }) => {
  const { userId: paramsUserId } = useParams();
  const userId = propUserId || paramsUserId;
  
  const [user, setUser] = useState(null);
  const [contributions, setContributions] = useState({});
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserDetails = useCallback(async () => {
    console.log('Fetching user details for userId:', userId);
    const startTime = performance.now();
    const { data, error } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', userId)
      .single();

    if (error) {
      console.error('Error fetching user details:', error);
      return null;
    }
    const endTime = performance.now();
    console.log(`Fetching user details took ${endTime - startTime} ms`);
    return data;
  }, [userId]);

  const fetchUserContributions = useCallback(async (email) => {
    console.log('Fetching user contributions');
    const startTime = performance.now();
    const endDate = DateTime.now().setZone('America/Los_Angeles');
    const startDate = endDate.minus({ days: 365 });

    const [oldApplications, newApplications, draftGenerations] = await Promise.all([
      fetchOldApplications(startDate, endDate, email),
      fetchNewApplications(startDate, endDate, userId),
      fetchDraftGenerations(startDate, endDate, email)
    ]);

    const combinedData = [...oldApplications, ...newApplications, ...draftGenerations];
    combinedData.sort((a, b) => DateTime.fromISO(b.timestamp) - DateTime.fromISO(a.timestamp));

    const endTime = performance.now();
    console.log(`Fetching user contributions took ${endTime - startTime} ms`);

    return {
      combinedData,
      activityByDate: processActivityByDate(combinedData)
    };
  }, [userId]);

  const fetchOldApplications = async (startDate, endDate, email) => {
    const { data, error } = await supabase
      .from('applications')
      .select('*')
      .eq('email', email)
      .gte('timestamp', startDate.toISO())
      .lte('timestamp', endDate.toISO());

    if (error) {
      console.error('Error fetching old applications:', error);
      return [];
    }
    return data.map(app => ({ ...app, type: 'application', isOld: true }));
  };

  const fetchNewApplications = async (startDate, endDate, userId) => {
    const { data, error } = await supabase
      .from('applications_vector_history')
      .select('*')
      .eq('user_id', userId)
      .gte('timestamp', startDate.toISO())
      .lte('timestamp', endDate.toISO());

    if (error) {
      console.error('Error fetching new applications:', error);
      return [];
    }
    return data.map(app => ({ ...app, type: 'application', isOld: false }));
  };

  const fetchDraftGenerations = async (startDate, endDate, email) => {
    const { data, error } = await supabase
      .from('draft_generations')
      .select('*')
      .eq('email', email)
      .gte('created_at', startDate.toISO())
      .lte('created_at', endDate.toISO());

    if (error) {
      console.error('Error fetching draft generations:', error);
      return [];
    }
    return data.map(draft => ({ ...draft, type: 'draft', timestamp: draft.created_at }));
  };

  const processActivityByDate = (combinedArray) => {
    const activityByDate = {};
    combinedArray.forEach(item => {
      const pdtDate = DateTime.fromISO(item.timestamp)
        .setZone('America/Los_Angeles')
        .toISODate();

      if (!activityByDate[pdtDate]) {
        activityByDate[pdtDate] = { applications: 0, draftGenerations: 0 };
      }

      if (item.type === 'application') {
        activityByDate[pdtDate].applications++;
      } else if (item.type === 'draft') {
        activityByDate[pdtDate].draftGenerations++;
      }
    });
    return activityByDate;
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log('Fetching data for IndividualProfile');
      setIsLoading(true);
      try {
        const userDetails = await fetchUserDetails();
        if (userDetails) {
          setUser(userDetails);
          const userContributions = await fetchUserContributions(userDetails.email);
          setActivities(userContributions.combinedData);
          setContributions(userContributions.activityByDate);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId, fetchUserDetails, fetchUserContributions]);

  const renderUserProfile = useMemo(() => {
    if (!user) return null;

    return (
      <div className="user-profile">
        <div className="profile-section main-info">
          <h2>{user.name || 'N/A'}</h2>
          <p><strong>UUID:</strong> {user.id}</p>
          <p><strong>Email:</strong> {user.email}</p>
          <p><strong>Society:</strong> {user.society || 'N/A'}</p>
          <p><strong>Work Experience:</strong> {user.work_experience || 'N/A'}</p>
          <p><strong>Created At:</strong> {user.created_at ? DateTime.fromISO(user.created_at).toLocaleString(DateTime.DATETIME_MED) : 'N/A'}</p>
          <p><strong>Updated At:</strong> {user.updated_at ? DateTime.fromISO(user.updated_at).toLocaleString(DateTime.DATETIME_MED) : 'N/A'}</p>
        </div>
        <div className="profile-section education-info">
          <h3>Education</h3>
          <p><strong>Education:</strong> {user.education || 'N/A'}</p>
          <p><strong>Undergraduate University:</strong> {user.undergraduate_uni || 'N/A'}</p>
          <p><strong>Undergraduate Grades:</strong> {user.undergraduate_grades || 'N/A'}</p>
          <p><strong>Sub Categories:</strong> {user.sub_categories || 'N/A'}</p>
        </div>
        <div className="profile-section activity-info">
          <h3>Activity</h3>
          <p><strong>Reference:</strong> {user.reference || 'N/A'}</p>
          <p><strong>Last Active:</strong> {user.last_active ? DateTime.fromISO(user.last_active).toLocaleString(DateTime.DATETIME_MED) : 'N/A'}</p>
          <p><strong>Credits:</strong> {user.credits || 0}</p>
        </div>
        <div className="profile-section subscription-info">
          <h3>Subscription</h3>
          <p><strong>Has Plus:</strong> <span className={`subscription-status ${user.hasPlus ? 'active' : 'inactive'}`}>{user.hasPlus ? 'Yes' : 'No'}</span></p>
          <p><strong>Stripe Customer ID:</strong> {user.stripe_customer_id || 'N/A'}</p>
        </div>
      </div>
    );
  }, [user]);

  const renderActivityAccordion = useMemo(() => {
    if (!activities || activities.length === 0) return <p>No activities found for this user.</p>;

    const truncate = (str, n) => {
      if (!str) return '';
      const words = str.split(' ');
      return words.length > n ? words.slice(0, n).join(' ') + '...' : str;
    };

    const getSnippet = (activity) => {
      if (activity.type === 'application') {
        return truncate(activity.application_text, 50);
      } else {
        return [
          truncate(activity.key_reasons, 25),
          truncate(activity.relevant_experience, 25),
          truncate(activity.relevant_interests, 25),
          truncate(activity.personal_info, 25)
        ].filter(Boolean).join(' | ');
      }
    };

    return (
      <div className="activity-accordion">
        <h3>User Activities</h3>
        {activities.map((activity, index) => (
          <details key={index} className="activity-item">
            <summary>
              <div className="activity-summary-row">
                <span className="activity-date">
                  {DateTime.fromISO(activity.timestamp).toLocaleString(DateTime.DATETIME_MED)}
                </span>
                <span className="activity-type">
                  {activity.type === 'application' 
                    ? `Application (${activity.isOld ? 'Old' : 'New'})`
                    : 'Draft'}
                </span>
              </div>
              <div className="activity-snippet">{getSnippet(activity)}</div>
            </summary>
            <div className="activity-details">
              {activity.type === 'draft' ? (
                <>
                  <p><strong>Question:</strong> {activity.question}</p>
                  <p><strong>Key Reasons:</strong> {activity.key_reasons}</p>
                  <p><strong>Relevant Experience:</strong> {activity.relevant_experience}</p>
                  <p><strong>Relevant Interests:</strong> {activity.relevant_interests}</p>
                  <p><strong>Personal Info:</strong> {activity.personal_info}</p>
                  <p><strong>Generated Draft:</strong> {activity.generated_draft}</p>
                </>
              ) : (
                <>
                  <p><strong>Question:</strong> {activity.question}</p>
                  <p><strong>Feedback:</strong> {activity.feedback}</p>
                  <p><strong>Device:</strong> {activity.device || 'N/A'}</p>
                  <p><strong>Screen Size:</strong> {activity.screen_size || 'N/A'}</p>
                  <p><strong>Application Text:</strong> {activity.application_text}</p>
                </>
              )}
            </div>
          </details>
        ))}
      </div>
    );
  }, [activities]);

  if (isLoading) {
    return <p>Loading user profile...</p>;
  }

  if (!user) {
    return <p>User not found.</p>;
  }

  return (
    <div className="individual-profile">
      {renderUserProfile}
      <ContributionHistory
        data={contributions}
        title="User Activity"
        todayCount={`Total Credits: ${user.credits || 0}`}
        isActivity={true}
      />
      {renderActivityAccordion}
    </div>
  );
};

export default IndividualProfile;