import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import Admin from './Admin/Admin';
import PasswordPrompt from './PasswordPrompt';
import FormattingDocs from './Admin/Search/FormattingDocs';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authCookie = Cookies.get('isAuthenticated');
    if (authCookie === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleCorrectPassword = () => {
    setIsAuthenticated(true);
    Cookies.set('isAuthenticated', 'true', { expires: 7 }); // Cookie expires in 7 days
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    Cookies.remove('isAuthenticated');
  };

  return (
    <Router>
      <div className="app">
        {!isAuthenticated ? (
          <PasswordPrompt onCorrectPassword={handleCorrectPassword} />
        ) : (
          <Routes>
            <Route path="/*" element={<Admin onLogout={handleLogout} />} />
            <Route path="/formatting-docs" element={<FormattingDocs />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
