import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import './PromptList.css';

const PromptList = () => {
  const [firms, setFirms] = useState([]);
  const [selectedFirm, setSelectedFirm] = useState('');
  const [promptData, setPromptData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);

  useEffect(() => {
    fetchFirms();
    fetchSummaryData();
  }, []);

  useEffect(() => {
    if (selectedFirm) {
      fetchPromptData(selectedFirm);
    }
  }, [selectedFirm]);

  const fetchFirms = async () => {
    try {
      const { data, error } = await supabase
        .from('firms')
        .select('name')
        .order('name');

      if (error) throw error;
      setFirms(data);
      if (data.length > 0) {
        setSelectedFirm(data[0].name);
      }
    } catch (error) {
      console.error('Error fetching firms:', error);
    }
  };

  const fetchPromptData = async (firmName) => {
    try {
      const { data: firmData, error: firmError } = await supabase
        .from('firms')
        .select('*')
        .eq('name', firmName)
        .single();

      if (firmError) throw firmError;

      const { data: questions, error: questionsError } = await supabase
        .from('questions')
        .select('*')
        .eq('firm', firmName);

      if (questionsError) throw questionsError;

      setPromptData({ firm: firmData, questions });
    } catch (error) {
      console.error('Error fetching prompt data:', error);
    }
  };

  const fetchSummaryData = async () => {
    try {
      const { data: firms, error: firmsError } = await supabase
        .from('firms')
        .select('*');

      if (firmsError) throw firmsError;

      const { data: questions, error: questionsError } = await supabase
        .from('questions')
        .select('*');

      if (questionsError) throw questionsError;

      const summary = {
        opentext_prompt: { present: 0, null: 0 },
        workexp_prompt: { present: 0, null: 0 },
        education_prompt: { present: 0, null: 0 },
        review_prompt: { present: 0, null: 0 },
        draft_prompt: { present: 0, null: 0 },
        question_review_prompt: { present: 0, null: 0 },
        question_draft_prompt: { present: 0, null: 0 },
      };

      firms.forEach(firm => {
        Object.keys(summary).forEach(key => {
          if (key !== 'question_review_prompt' && key !== 'question_draft_prompt') {
            summary[key][firm[key] ? 'present' : 'null']++;
          }
        });
      });

      questions.forEach(question => {
        summary.question_review_prompt[question.review_system_prompt ? 'present' : 'null']++;
        summary.question_draft_prompt[question.draft_system_prompt ? 'present' : 'null']++;
      });

      setSummaryData(summary);
    } catch (error) {
      console.error('Error fetching summary data:', error);
    }
  };

  const renderPromptStatus = (prompt, name) => (
    <li className={`prompt-item ${prompt ? 'has-prompt' : 'no-prompt'}`}>
      <span className="prompt-name">{name}:</span>
      <span className="prompt-status">{prompt ? 'Present' : 'Null'}</span>
    </li>
  );

  const renderSummaryItem = (data, name) => (
    <li className="summary-item">
      <span className="summary-name">{name}:</span>
      <span className="summary-status">
        <span className="present">{data.present} Present</span>
        <span className="null">{data.null} Null</span>
      </span>
    </li>
  );

  return (
    <div className="prompt-list-container">
      <div className="header-row">
        <div className="header-left">
          <h2 className="prompt-list-title">Prompt Status</h2>
          <div className="firm-select-container">
            <label htmlFor="firm-select">Select Firm: </label>
            <select
              id="firm-select"
              value={selectedFirm}
              onChange={(e) => setSelectedFirm(e.target.value)}
            >
              {firms.map((firm) => (
                <option key={firm.name} value={firm.name}>
                  {firm.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {summaryData && (
          <div className="header-right">
            <h3>Summary (All Firms)</h3>
            <ul className="summary-list">
              {renderSummaryItem(summaryData.opentext_prompt, 'OpenText Prompt')}
              {renderSummaryItem(summaryData.workexp_prompt, 'Work Experience Prompt')}
              {renderSummaryItem(summaryData.education_prompt, 'Education Prompt')}
              {renderSummaryItem(summaryData.review_prompt, 'Review Prompt')}
              {renderSummaryItem(summaryData.draft_prompt, 'Draft Prompt')}
              {renderSummaryItem(summaryData.question_review_prompt, 'Question Review Prompt')}
              {renderSummaryItem(summaryData.question_draft_prompt, 'Question Draft Prompt')}
            </ul>
          </div>
        )}
      </div>
      {promptData && (
        <div className="prompt-list two-column">
          <div className="column">
            <h3>{promptData.firm.name} Prompts</h3>
            <ul>
              {renderPromptStatus(promptData.firm.opentext_prompt, 'OpenText Prompt')}
              {renderPromptStatus(promptData.firm.workexp_prompt, 'Work Experience Prompt')}
              {renderPromptStatus(promptData.firm.education_prompt, 'Education Prompt')}
              {renderPromptStatus(promptData.firm.review_prompt, 'Review Prompt')}
              {renderPromptStatus(promptData.firm.draft_prompt, 'Draft Prompt')}
            </ul>
          </div>
          <div className="column">
            <h3>Questions</h3>
            {promptData.questions.map((question, index) => (
              <div key={index} className="question-item">
                <p className="question-text">{question.question}</p>
                <ul>
                  {renderPromptStatus(question.review_system_prompt, 'Review System Prompt')}
                  {renderPromptStatus(question.draft_system_prompt, 'Draft System Prompt')}
                </ul>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PromptList;